<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
    >
      <el-form-item label="类型：" prop="scheduleType">
        <el-select
          filterable
          v-model="ruleForm.scheduleType"
          placeholder="请选择"
          style="width: 100%"
          @change="changeType"
          id="projectScheduleType"
        >
          <el-option
            v-for="(i, index) in scheduleTypeList"
            :key="index"
            :label="i.label"
            :value="i.val"
            :id="'projectScheduleTypeList' + index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期：" prop="scheduleTime">
        <el-date-picker
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          placeholder="选择日期"
          v-model="ruleForm.scheduleTime"
          style="width: 100%"
          id="projectScheduleTime"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注：" prop="comment">
        <el-input
          v-model="ruleForm.comment"
          type="textarea"
          :row="3"
          maxlength="100"
          id="projectComment"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="关联客户联系人："
        prop="relatePersonId"
        v-if="!isrequired"
      >
        <el-select
          filterable
          v-model="ruleForm.relatePersonId"
          placeholder="请选择"
          style="width: 83%"
          id="projectRelatePersonId"
        >
          <el-option
            v-for="(i, index) in contactsList"
            :label="i.name"
            :key="i.id"
            :value="i.id"
            :id="'projectContactsList' + index"
          ></el-option>
        </el-select>
        <el-button
          size="mini"
          style="margin-left: 10px"
          @click="addCustomerContact"
          id="projectAddCustomerContact"
          >增加</el-button
        >
      </el-form-item>
      <el-form-item
        label="关联候选人："
        :rules="[
          {
            required: this.ruleForm.scheduleType == '其他' ? false : isrequired,
            trigger: 'change',
            message: '请选择候选人',
          },
        ]"
        prop="relatePersonId"
        v-else
      >
        <el-select
          filterable
          v-model="ruleForm.relatePersonId"
          placeholder="请选择"
          :style="{ width: moduleType.type == '客户' ? '84%' : '100%' }"
          id="projectRelatePersonId"
        >
          <el-option
            v-for="(i, index) in relatePersonList"
            :label="i.name"
            :key="i.id"
            :value="i.id"
            :id="'projectRelatePersonList' + index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="参与者：" class="fromInput" prop="joinUserIds">
        <el-select
          multiple
          filterable
          collapse-tags
          v-model="ruleForm.joinUserIds"
          placeholder="请选择"
          style="width: 100%"
          id="projectJoinUserIds"
        >
          <el-option
            v-for="(i, index) in userList"
            :label="i.name"
            :value="i.id"
            :key="i.id"
            :id="'projectJoinUserList' + index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-dialog
      title="客户联系人"
      :visible.sync="addCustomerContactDialog"
      width="600px"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="customerContact"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.name"
            maxlength="20"
            id="projectScheduleCustomerContactName"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.position"
            maxlength="20"
            id="projectScheduleCustomerContactPosition"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.tel"
            maxlength="50"
            id="projectScheduleCustomerContactTel"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.email"
            maxlength="50"
            id="projectScheduleCustomerContactEmail"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.wechatOrOther"
            maxlength="50"
            id="projectScheduleCustomerContactWechatOrOther"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="addCustomerContactDialog = false"
          size="small"
          id="projectScheduleCustomerContactCencel"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="saveCustomerContact('linkManFrom')"
          id="projectScheduleCustomerContactSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserBaseInfos,
  scheduleAddOrEdit,
  contactsAddOrEdit,
  getContactsBaseInfos, //获取客户联系人
  GetsysResumeOption, //获取关联人
} from "../../api/api";

export default {
  name: "schedule",
  props: [
    "targetId",
    "propScheduleData",
    "edit",
    "moduleType",
    "objectId",
    "companyId",
  ],
  data() {
    return {
      relatePersonList: [],
      userList: [],
      addCustomerContactDialog: false,
      customerContact: {
        companyId: 0,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      ruleForm: {
        comment: "",
        id: "",
        joinUserIds: [],
        moduleType: "",
        relatePersonId: "",
        scheduleTime: "",
        scheduleType: "顾问面试",
        targetId: 0,
        version: 0,
      },
      scheduleTypeList: [
        {
          label: "顾问面试",
          val: "顾问面试",
        },
        {
          label: "客户面试",
          val: "客户面试",
        },
        {
          label: "候选人电话",
          val: "候选人电话",
        },
        {
          label: "候选人背调",
          val: "候选人背调",
        },
        {
          label: "offer谈判",
          val: "offer谈判",
        },
        {
          label: "候选人入职",
          val: "候选人入职",
        },
        {
          label: "候选人开票",
          val: "候选人开票",
        },
        {
          label: "试用期结束",
          val: "试用期结束",
        },
        { label: "客户电话", val: "客户电话" },
        { label: "客户拜访", val: "客户拜访" },
        { label: "客户邮件", val: "客户邮件" },
        { label: "其他", val: "其他" },
      ],
      rules: {
        relatePersonId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        scheduleType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        scheduleTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      isrequired: true,
      contactsList: [],
    };
  },
  created() {
    this.targetId
      ? (this.ruleForm = {})
      : (this.ruleForm = { scheduleType: "顾问面试" });
    this.contactsOptions(this.objectId);
    this.getUser();
    this.loadContactsLitst(this.companyId);
  },
  watch: {
    propScheduleData(n, o) {
      this.ruleForm = n;
      if (
        n.scheduleType === "客户电话" ||
        n.scheduleType === "客户拜访" ||
        n.scheduleType === "客户邮件"
      ) {
        this.isrequired = false;
      } else {
        this.isrequired = true;
      }
    },
  },
  methods: {
    //项目类型改变
    changeType(val) {
      if (val === "客户电话" || val === "客户拜访" || val === "客户邮件") {
        this.isrequired = false;
        this.loadContactsLitst(this.companyId);
        // this.$refs.calendardata.clearValidate('targetId');
      } else {
        this.isrequired = true;
      }
    },
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    // 保存客户联系
    saveCustomerContact() {
      let that = this;
      that.customerContact.name = that.trim(that.customerContact.name);
      that.customerContact.position = that.trim(that.customerContact.position);
      contactsAddOrEdit(this.customerContact).then((res) => {
        this.addCustomerContactDialog = false;
        this.ruleForm.relatePersonId = res.result;
        this.loadContactsLitst(this.companyId);
      });
    },

    addCustomerContact() {
      this.ruleForm.relatePersonId = "";
      this.addCustomerContactDialog = true;
      this.customerContact = {};
      this.customerContact.companyId = this.companyId;
    },
    submitForm(formName) {
      // debugger
      return new Promise((resole) => {
        this.ruleForm.moduleType = this.moduleType.type;
        this.ruleForm.targetId =
          this.moduleType.type == "客户" ? this.targetId : this.objectId;
        // debugger
        this.$refs[formName].validate((valid) => {
          if (valid) {
            scheduleAddOrEdit(this.ruleForm).then((res) => {
              resole();
            });
          }
        });
      }).catch((error) => {});
    },
    getUser() {
      getUserBaseInfos().then((res) => {
        this.userList = res.result;
      });
    },
    //关联候选人
    contactsOptions(id) {
      GetsysResumeOption({ id: id }).then((res) => {
        this.relatePersonList = res.result;
      });
    },
    //获取客户联系人
    loadContactsLitst(id, type) {
      if (id) {
        getContactsBaseInfos({ companyId: id }).then((res) => {
          if (res.success) {
            this.contactsList = res.result;
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="time-line">
      <div v-for="item in OperateList" class="time-line-div">
        <div class="time-line_l">
          <p class="time">{{ item.date }}</p>
          <p class="name">{{ item.name }}</p>
        </div>
        <p class="line"></p>
        <div class="time-line_r">
          <div class="time-line_con">
            <p class="r_title" v-if="item.type">
              <span
                class="tag"
                :class="{
                  tag08: item.type == '上传附件',
                  tag09: item.type == '修改内容',
                  tag10: item.type == '新增备注',
                  tag11: item.type == '新增合同',
                  tag12: item.type == '新增日程',
                  tag13: item.type == '新增客户联系人',
                  tag14: item.type == '创建客户',
                  tag15: item.type == '新增项目',
                }"
                >{{ item.type }}</span
              >
            </p>
            <div class="r_con">
              <div v-if="item.type == '上传附件'">
                {{ JSON.parse(item.content[0]).titleContent }}
                <span
                  v-for="i in item.content"
                  class="tabHref mgr10"
                  @click="preView(JSON.parse(i).objectId)"
                  >{{ JSON.parse(i).objectName }}</span
                >
              </div>
              <div v-else-if="item.type == '新增备注'" v-html="item.content"></div>
              <div v-else-if="item.type == '新增合同'"></div>
              <div v-else-if="item.type == '新增合同'"></div>
              <div v-else-if="item.type == '创建项目'">
                {{ JSON.parse(item.content[0]).titleContent }}【<span
                  class="tabHref"
                  @click="toInfo(JSON.parse(item.content[0]).objectId)"
                  >{{ JSON.parse(item.content[0]).objectName }}</span
                >】
              </div>
              <div v-else v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="dott"></div>
    </div>
    <el-dialog
      :visible.sync="dialogVisablePre"
      width="600px"
      title="文件预览"
      append-to-body
    >
      <img style="width: 100%" :src="imgSrc" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getProOperateList, //获取操作日志列表
  getAtachmentInfo,
} from "../../api/api";
import { ApiBaseUrl, PreviewUrl } from "../../api/http";
export default {
  name: "attachment",
  props: ["objectId"],
  data() {
    return {
      OperateList: [],
      imgSrc: "",
      dialogVisablePre: false,
    };
  },
  created() {
    this.getProOperateList(this.objectId);
  },
  methods: {
    toInfo(id) {
      this.$emit("toInfo");
    },
    preView(id) {
      getAtachmentInfo(id).then((res) => {
        if (res.success) {
          if (res.result.fileType === "application/pdf") {
            //pdf文件查看
            window.open(
              "/pdf/web/viewer.html?file=" + PreviewUrl + res.result.filePath
            );
          } else if (
            res.result.filePath.indexOf("document") >= 0 ||
            res.result.filePath.indexOf("sheet") >= 0 ||
            res.result.fileType == "application/msword" ||
            res.result.fileType == "application/vnd.ms-excel" ||
            res.result.fileType == "application/vnd.ms-powerpoint" ||
            res.result.fileType ==
              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            res.result.fileType ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            //wps查看
            window.open(
              "https://view.officeapps.live.com/op/view.aspx?src=" +
                PreviewUrl +
                res.result.filePath
            );
          } else if (res.result.fileType === "application/zip") {
            this.$message.error("附件类型不支持在线预览");
          } else {
            if (res.result.filePath.indexOf("rar") >= 0) {
              this.$message.error("附件类型不支持在线预览");
            } else {
              //图片
              this.dialogVisablePre = true;
              this.imgSrc = ApiBaseUrl + res.result.filePath;
            }
          }
        }
      });
    },
    getProOperateList(id) {
      getProOperateList(id).then((res) => {
        if (res.success) {
          this.OperateList = res.result;
          this.OperateList.forEach((item) => {
            if (
              item.type == "上传附件" ||
              item.type == "创建项目" ||
              item.type == "创建客户"
            ) {
              item.content =
                (item.content.indexOf("<br/>") != -1 &&
                  item.content
                    .split("<br/>")
                    .filter((item) => item && JSON.stringify([item]))) ||
                [];
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.time-line {
  position: relative;
  padding:0 15px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
  margin-top: 15px;
  &:nth-child(1) {
    margin-top: 0 !important;
  }
  .time-line_l {
    position: absolute;
    left: 0;
    width: 135px;
    text-align: right;
    p {
      margin: 0;
      line-height: 1;
    }
    .time {
      padding-top: 24px;
      font-size: 12px;
      color: #999;
    }
    .name {
      font-size: 13px;
      margin-top: 10px;
      color: #606266;
    }
  }
  .line {
    position: absolute;
    left: 155px;
    width: 12px;
    height: 12px;
    top: 20px;
    background: #e4e7ed;
    border-radius: 50%;
    z-index: 10;
  }
  .time-line_r {
    position: relative;
    left: 191px;
    width: calc(100% - 180px);
    .time-line_con {
      border: 1px solid #e5e5e5;
      .r_title {
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
        margin: 0;
        padding-left: 10px;
        border-bottom: 1px solid #e5e5e5;
      }
      .r_con {
        padding: 15px 20px;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
}
.dott {
  position: absolute;
  width: 5px;
  left: 173px;
  height: calc(100% - 45px);
  top: 15px;
  bottom: 5px;
  z-index: 1;
  background-color: #e5e5e5;
}
</style>

<template>
  <section
    style="
      background-color: #fff;
      padding: 20px;
      margin: 0 15px;
      border: 1px #e5e5e5 solid;
    "
    v-loading="screenloading"
  >
    <div class="drawerCon_head">
      <p class="name">
        {{ Info.projectName
        }}<span style="color: gray">（ID{{ Info.id }}）</span>
        <span class="secureIcon" v-if="Info.secure">保密</span>
        <span class="comStatus" v-if="Info.status">{{ Info.status }}</span>
        <span class="subInfo">
          项目经理：<i>{{ Info.managerName ? Info.managerName : "-" }}</i>
          创建时间：<i>{{
            Info.createdTime ? Info.createdTime.substring(0, 10) : "-"
          }}</i>
          最近操作：<i>
            {{
              Info.lastOperateTime ? Info.lastOperateTime.substring(0, 10) : "-"
            }}
          </i>
        </span>
      </p>
      <span class="company tabHref" @click="toCustomer(Info.companyId)">
        {{ Info.companyName }}
      </span>
    </div>
    <div class="drawerCon_con clearfix" v-if="Count">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          label="详情"
          name="1"
          id="detailProjectDetail"
        ></el-tab-pane>
        <el-tab-pane
          :label="'候选人' + Count.resumeCount"
          name="2"
        ></el-tab-pane>
        <el-tab-pane label="数据概览" name="8"></el-tab-pane>
        <el-tab-pane
          :label="'相似职位' + Count.similarProjectCount"
          name="7"
        ></el-tab-pane>
        <el-tab-pane :label="'备注' + Count.markCount" name="3"></el-tab-pane>
        <el-tab-pane
          :label="'附件' + Count.attachmentCount"
          name="4"
        ></el-tab-pane>
        <el-tab-pane
          :label="'日程' + Count.scheduleCount"
          name="5"
        ></el-tab-pane>
        <el-tab-pane :label="'操作日志'" name="6"></el-tab-pane>
      </el-tabs>
      <div class="info_con" v-if="activeName == '1'">
        <div class="drawerCon_con_tabs">
          <p class="drawerCon_from_tit">基本信息</p>
          <el-form label-width="90px" label-position="left">
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="职位：">
                  <div class="canEdit">
                    {{ Info.position ? Info.position : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      @show="getInfoById(Info.id)"
                      trigger="click"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            style="width: 300px"
                            v-model="formdata.position"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('position')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="客户公司：">
                  <div class="canEdit">
                    {{ Info.companyName ? Info.companyName : "-" }}
                  </div></el-form-item
                >
              </el-col>
              <el-col :span="12">
                <el-form-item label="职位别名：">
                  <div class="canEdit">
                    {{ Info.positionAlias ? Info.positionAlias : "-" }}
                  </div></el-form-item
                >
              </el-col>
              <el-col :span="11">
                <el-form-item label="需求数量：">
                  <div class="canEdit">
                    {{ Info.hireCount ? Info.hireCount : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      @show="getInfoById(Info.id)"
                      trigger="click"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            style="width: 300px"
                            type="number"
                            class="numberHeight"
                            v-model.number="formdata.hireCount"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('hireCount')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="客户联系人：">
                  <div class="canEdit">
                    {{ Info.contactNames ? Info.contactNames : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="
                        getInfoById(Info.id);
                        loadContactsLitst(Info.companyId);
                      "
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            placeholder="请选择"
                            v-model="formdata.companyContactIds"
                            multiple
                            filterable
                            collapse-tags
                            style="width: 350px"
                          >
                            <el-option
                              v-for="i in contactsList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('companyContactIds', 'array')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="职能：">
                  <div class="canEdit" style="word-break: break-all">
                    <p
                      style="max-width: 500px"
                      slot="reference"
                      :title="Info.functionName"
                      class="text-overflows"
                    >
                      {{ Info.functionName ? Info.functionName : "-" }}
                    </p>
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i
                        slot="reference"
                        style="position: absolute; top: 3px; right: 0"
                        class="el-icon-edit tabHref"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            v-model="formdata.funIds"
                            :options="posList"
                            :props="props"
                            style="width: 300px"
                            clearable
                            filterable
                            collapse-tags
                            :show-all-levels="false"
                            placeholder="请选择"
                          ></el-cascader>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('funIds', 'array')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="月薪：">
                  <div class="canEdit">
                    <span v-if="Info.monthlySalary"
                      >{{ Info.monthlySalary }}元/月 x
                      {{ Info.monthCount }}个月</span
                    >
                    <span v-else>-</span>
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      @show="getInfoById(Info.id)"
                      trigger="click"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-row style="width: 300px">
                            <el-col :span="13">
                              <el-input
                                type="number"
                                class="numberHeight"
                                v-model.number="formdata.monthlySalary"
                              >
                                <span slot="suffix">元/月</span>
                              </el-input>
                            </el-col>
                            <el-col
                              :span="2"
                              style="
                                text-align: center;
                                color: #6e6f73;
                                line-height: 32px;
                              "
                              >X</el-col
                            >
                            <el-col :span="9">
                              <el-input
                                maxlength="9"
                                type="number"
                                class="numberHeight"
                                v-model.number="formdata.monthCount"
                                ><span slot="suffix">个月</span></el-input
                              >
                            </el-col>
                          </el-row>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('monthlySalary')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="状态：">
                  <div class="canEdit">
                    <span
                      >{{ Info.status ? Info.status : "-"
                      }}{{
                        Info.failRemark ? "(" + Info.failRemark + ")" : ""
                      }}</span
                    >
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            placeholder="请选择"
                            filterable
                            v-model="formdata.status"
                            style="width: 300px"
                          >
                            <el-option
                              v-for="(item, index) in projectType"
                              :key="'a_' + index"
                              :value="item.value"
                              :label="item.value"
                            >
                            </el-option>
                          </el-select>
                          <div style="margin-top: 10px">
                            <el-select
                              v-if="formdata.status == '失败'"
                              filterable
                              v-model="formdata.failRemark"
                              placeholder="请选择失败原因"
                              style="width: 300px"
                            >
                              <el-option
                                v-for="(item, index) in projectFailType"
                                :key="'c_F_' + index"
                                :value="item.val"
                                :label="item.name"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('status')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="城市：">
                  <div class="canEdit">
                    {{ Info.cityName ? Info.cityName : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            style="width: 300px"
                            v-model="formdata.cityIds"
                            :options="cityList"
                            collapse-tags
                            :show-all-levels="false"
                            :props="{
                              value: 'id',
                              label: 'name',
                              children: 'children',
                              multiple: true,
                              checkStrictly: true,
                            }"
                            placeholder="请选择"
                          ></el-cascader>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('cityIds', 'array')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="开始时间：">
                  <div class="canEdit">
                    {{ Info.startDate ? Info.startDate.substring(0, 10) : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-date-picker
                            v-model="formdata.startDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            :clearable="false"
                            style="width: 300px"
                            placeholder="请选择日期"
                          >
                          </el-date-picker>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('startDate')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结束时间：">
                  <div class="canEdit">
                    {{ Info.dueDate ? Info.dueDate.substring(0, 10) : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-date-picker
                            v-model="formdata.dueDate"
                            value-format="yyyy-MM-dd"
                            :picker-options="endOptions"
                            :clearable="false"
                            type="date"
                            style="width: 300px"
                            placeholder="请选择日期"
                          >
                          </el-date-picker>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('dueDate')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="合同：">
                  <div class="canEdit">
                    {{ Info.contractName ? Info.contractName : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            placeholder="请选择"
                            filterable
                            v-model="formdata.contractId"
                            style="width: 100%"
                            no-data-text="该客户下无合同"
                          >
                            <el-option
                              v-for="item in contractList"
                              :key="item.id"
                              :value="item.id"
                              :label="item.name"
                              :disabled="!item.canSelected"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('contractId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="参与顾问：">
                  <div class="canEdit">
                    {{ Info.isPublic ? "全员" : Info.permissionUserNames }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            clearable
                            filterable
                            collapse-tags
                            multiple
                            style="width: 300px"
                            ref="permissionRef"
                            v-model="formdata.permissionUserIds"
                            placeholder="请选择"
                          >
                            <div
                              style="
                                overflow: hidden;
                                background: #eee;
                                padding: 10px 5px;
                                margin-bottom: 5px;
                              "
                            >
                              <el-button
                                size="mini"
                                @click="formdata.permissionUserIds = allChecked"
                                >全选</el-button
                              >
                              <el-button
                                size="mini"
                                @click="formdata.permissionUserIds = []"
                                >全不选</el-button
                              >
                              <el-button
                                size="mini"
                                style="float: right"
                                @click="$refs['permissionRef'].blur()"
                                >确认</el-button
                              >
                            </div>
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('permissionUserIds', 'array')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="项目BD：">
                  <div class="canEdit">
                    {{
                      Info.newProjectManagerName
                        ? Info.newProjectManagerName
                        : "-"
                    }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            clearable
                            filterable
                            style="width: 300px"
                            v-model="formdata.newprojectManagerId"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('newprojectManagerId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目经理：">
                  <div class="canEdit">
                    {{ Info.managerName ? Info.managerName : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            clearable
                            filterable
                            style="width: 300px"
                            v-model="formdata.managerId"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('managerId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form label-width="90px" label-position="left">
            <el-row :gutter="24">
              <el-col :span="22">
                <el-form-item label="项目描述：">
                  <div class="canEdit">
                    {{ Info.jd ? Info.jd : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getInfoById(Info.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            maxlength="500"
                            style="width: 300px"
                            v-model="formdata.jd"
                            type="textarea"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('jd')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="22">
                <el-form-item label="备注：">
                  <div class="canEdit">
                    {{ Info.comment ? Info.comment : "-" }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <p class="drawerCon_from_tit" style="margin-top: 9px">
            目标人才<span style="color: #999; font-size: 12px"
              >（完善以下信息将会更精准的为项目推荐人才）</span
            >
          </p>
          <el-form
            label-width="90px"
            label-position="left"
            style="margin-bottom: 15px"
          >
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="人才行业：">
                  <div class="canEdit" style="word-break: break-all">
                    <p v-if="Info.notLimitIndustry">不限</p>
                    <p
                      v-else
                      style="max-width: 500px"
                      slot="reference"
                      :title="Info.preferenceIndustries"
                      class="text-overflows"
                    >
                      {{
                        Info.preferenceIndustries
                          ? Info.preferenceIndustries
                          : "-"
                      }}
                    </p>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="工作年限：">
                  {{ Info.serviceYear ? Info.serviceYear : "-" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="人才标签：">
                  <span v-if="!Info.labels || Info.labels.length == 0">-</span>
                  <span
                    v-else
                    class="canEdit"
                    v-for="(i, index) in Info.labels"
                    :key="'labelIds' + index"
                  >
                    {{ index > 0 ? "、" + i.name : i.name }}
                  </span>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="年龄：">
                  {{ Info.age ? Info.age : "-" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="22">
                <el-form-item label="目标公司：">
                  <span
                    v-if="
                      !Info.targetCompanies || Info.targetCompanies.length == 0
                    "
                    >-</span
                  >
                  <div
                    v-else
                    class="canEdit"
                    v-for="(i, index) in Info.targetCompanies"
                    :key="'targetCompanies' + index"
                  >
                    {{ i }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="info_con_r">
          <div class="btnList clearfix">
            <div class="block" @click="EditPro" id="detailProjectEditPro">
              <i class="iconfont icon-kh_gsxq_jrwj"></i>
              <p class="demonstration">编辑</p>
            </div>
            <div class="block" @click="addMark()" id="detailProjectAddMark">
              <i class="iconfont icon-kh_gsxq_bz"></i>
              <p class="demonstration">备注</p>
            </div>
            <div
              class="block"
              @click="fileManagement = true"
              id="detailProjectAddfile"
            >
              <i class="iconfont icon-kh_gsxq_jrwj"></i>
              <p class="demonstration">加入文件夹</p>
            </div>
            <div
              class="block"
              @click="scheduleClick"
              id="detailProjectAddschedule"
            >
              <i class="iconfont icon-kh_gsxq_zdrc"></i>
              <p class="demonstration">新增日程</p>
            </div>
            <div
              class="block"
              @click="uploadAttach"
              id="detailProjectUploadAttach"
            >
              <i class="iconfont icon-kh_gsxq_bscfj"></i>
              <p class="demonstration">上传附件</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mark_con clearfix"
        v-if="activeName != '1'"
        :style="activeName == '2' ? 'max-height: 74vh;overflow: hidden;' : ''"
      >
        <pipeLine
          class="clearfix"
          v-if="activeName == '2'"
          :projectId="projectId"
          :proStatus="proStatus"
          :resumeNo="resumeNo"
          :countResumePosition="Count.countResumePosition"
          :projectName="Info.projectName"
          :fromPro="true"
          :source="source"
          :detailShowEntry="detailShowEntry"
          :Info="Info"
        ></pipeLine>
        <dataOverview
          ref="dataOverview"
          v-if="activeName == '8'"
          :projectId="projectId"
        ></dataOverview>
        <attach-ment
          v-if="activeName == '4'"
          :objectId="projectId"
          :moduleType="'项目'"
        ></attach-ment>
        <operate-list
          v-if="activeName == '6'"
          :objectId="projectId"
          @toInfo="activeName = '1'"
        ></operate-list>
        <div class="time-line" v-if="activeName == '3'" :key="reacodeKey">
          <mark-reacode
            v-if="activeName == '3'"
            :objectId="projectId"
            :moduleType="'项目'"
          ></mark-reacode>
        </div>
        <div v-show="activeName == '5'">
          <!--      日程-->
          <el-button size="mini" @click="scheduleClick">新增日程</el-button>
          <div>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              class="tabBorder"
              :data="scheduleDta"
              style="width: 100%"
            >
              <el-table-column label="任务类型" width="100">
                <template slot-scope="scope">
                  <span
                    class="tag"
                    :class="{
                      tag19: scope.row.scheduleType == '客户拜访',
                      tag20: scope.row.scheduleType == '候选人电话',
                      tag04: scope.row.scheduleType == '顾问面试',
                      tag06: scope.row.scheduleType == '客户面试',
                      tag03: scope.row.scheduleType == 'offer谈判',
                      tag05: scope.row.scheduleType == '候选人入职',
                      tag25: scope.row.scheduleType == '客户电话',
                      tag21: scope.row.scheduleType == '客户邮件',
                      tag01: scope.row.scheduleType == '联系候选人',
                    }"
                    >{{ scope.row.scheduleType }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="targetName"
                width="260"
                show-overflow-tooltip
                label="任务对象"
              >
                <template slot-scope="scope">
                  <span class="tabHref" @click="Toggle()">{{
                    scope.row.targetName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="关联人" width="300" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.scheduleType == '客户邮件' ||
                      scope.row.scheduleType == '客户电话' ||
                      scope.row.scheduleType == '客户拜访'
                    "
                  >
                    {{ scope.row.relatePersonName }}
                  </span>
                  <span
                    v-else
                    class="tabHref"
                    @click="toResume(scope.row.relatePersonId)"
                  >
                    {{ scope.row.relatePersonName }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="备注" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.comment ? scope.row.comment : "-" }}
                </template>
              </el-table-column>
              <el-table-column label="任务时间" width="180" prop="scheduleTime">
              </el-table-column>
              <el-table-column label="创建人" width="180" prop="createUserName">
              </el-table-column>
              <el-table-column label="参与者" width="180">
                <template slot-scope="scope">
                  {{ scope.row.joinUserName ? scope.row.joinUserName : "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <span class="tabHref" @click="editSchedule(scope.row.id)"
                    >编辑</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <similar-positions
          ref="similarPositions"
          v-if="activeName == '7'"
          :projectId="projectId"
        ></similar-positions>
      </div>
      <upload-attach-ment
        v-if="VisibleAttach"
        :way="1"
        @loadReload="attachToloadInfo"
        @cancelAttath="VisibleAttach = false"
        :dialogVisibleAttach="VisibleAttach"
        :objectId="projectId"
        :moduleType="'项目'"
      ></upload-attach-ment>
      <el-dialog
        :title="scheduleTitle"
        :visible.sync="scheduleDialog"
        width="600px"
        append-to-body
      >
        <div style="padding-right: 20px">
          <schedule
            ref="schdeule"
            v-if="scheduleDialog"
            :objectId="projectId"
            :moduleType="moduleType"
            :companyId="Info.companyId"
            :propScheduleData="propScheduleData"
          ></schedule>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            plain
            size="mini"
            @click="scheduleDialog = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            :loading="saveloading"
            size="mini"
            @click="saveSchedule()"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="备注"
        :visible.sync="dialogMarkVisible"
        width="600px"
        append-to-body
        :before-close="handleCloseResult"
      >
        <!-- 添加备注 -->
        <addMarkReacode
          v-if="dialogMarkVisible"
          :customId="projectId"
          resumeId=""
          :disableEdit="isNo"
          relativeType="项目"
          type="项目信息"
          @changeSaveLoading="changeSaveLoading"
          @loadRecodeList="loadRecodeList()"
          ref="saveMark"
        ></addMarkReacode>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogMarkVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="saveloading"
            @click="saveMark"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 文件夹 -->
      <el-dialog
        :visible.sync="fileManagement"
        :modal-append-to-body="false"
        append-to-body
        title="文件夹管理"
        :close-on-click-modal="false"
        width="720px"
      >
        <file
          v-if="fileManagement"
          @getFavoriteData="childByValue"
          @length="getLength"
          :type="'项目'"
          :checkbox="isCheckbox"
        ></file>
        <span slot="footer" class="dialog-footer">
          <el-button
            size="mini"
            @click="fileManagement = false"
            id="detailProjectFileCencel"
            >关闭</el-button
          >
          <el-button
            size="mini"
            :disabled="favoriteList.favoriteIds.length == 0"
            :loading="saveloading"
            @click="saveFavorite"
            type="primary"
            id="detailProjectFileSave"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <el-drawer
        :visible.sync="drawerVisible"
        custom-class="drawerClass"
        direction="ttb"
        :modal="false"
        append-to-body
      >
        <div slot="title" class="drawerTitle">编辑项目</div>
        <div class="drawerCon">
          <addore-edit
            v-if="drawerVisible"
            @loadList="loadInfoById"
            :projectId="projectId"
          ></addore-edit>
        </div>
      </el-drawer>
      <el-drawer
        :visible.sync="customerDrawerVisible"
        custom-class="drawerClass"
        direction="ttb"
        :modal="false"
        append-to-body
        :before-close="handleClose"
      >
        <div slot="title" class="drawerTitle">
          <i class="drawIcon iconfont icon-kh_xq"></i>客户
        </div>
        <div class="drawerCon">
          <customer
            :customId="customId1"
            :activeToInfo="activedToInfo"
            @loadList="loadInfoById"
          ></customer>
        </div>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {
  getProjectDetail,
  getproRelatedCount,
  addOrEditProject,
  getProjectById,
  getCommonColumnList,
  scheduleList,
  getEnableUserBaseInfos,
  getContactsBaseInfos,
  canOperate,
  editSchedule,
  addToFavorite,
  getContractBaseInfos,
  cityTree,
} from "../../api/api";
import {
  statisticsProjectSimilar, //记录相似职位点击与添加成功
  statisticsProjectResumedTabClick, //项目-项目详情-候选人tab/相似职位点击量
} from "../../api/buryingPoint";
import schedule from "../../components/project/schedule";
import * as data from "../../common/json/commData.json";
import attachMent from "../../components/attachMent";
import uploadAttachMent from "../../components/uploadAttachMent";
import operateList from "../../components/project/operateList"; //操作日志
import similarPositions from "../../components/project/similarPositions"; //相似职位
import addMarkReacode from "../../components/remark/remark"; //备注
import markReacode from "../../components/remark/markRecode"; //备注List
import file from "../../components/file/file"; //文件夹
import addoreEdit from "./../../components/project/addorEdit";
import dataOverview from "./../../components/project/dataOverview";
import customer from "./../../components/customer/customerInfo";
import pipeLine from "../Pipeline/index"; //pipeline
export default {
  name: "customerInfo",
  components: {
    file,
    attachMent,
    uploadAttachMent,
    schedule,
    operateList,
    addMarkReacode,
    markReacode,
    addoreEdit,
    pipeLine,
    customer,
    similarPositions,
    dataOverview, //数据概览
  },
  props: ["customId"],
  data() {
    return {
      contractList: [],
      resumeNo: null, //简历编号
      proStatus: "", //项目阶段
      screenloading: false,
      drawerVisible: false,
      projectId: null,
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isCheckbox: {
        val: true,
        edit: true,
      },
      fileManagement: false,
      dialogMarkVisible: false,
      saveloading: false,
      propScheduleData: {},
      VisibleAttach: false,
      scheduleTitle: "新增日程",
      scheduleDialog: false,
      //详情
      activeName: "2",
      scheduleListType: {
        id: 0,
        type: "项目",
      },
      moduleType: { type: "项目" },
      Info: "",
      Count: "",
      endOptions: "",
      posList: [],
      posList1: [],
      scheduleDta: [],
      cityList: [],
      userList: [],
      contactsList: [],
      allChecked: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      projectType: data.projectType,
      formdata: {
        cityIds: [],
        companyContactId: null,
        companyContactIds: [],
        companyId: null,
        contractId: null,
        dueDate: "",
        funIds: [],
        functionId: 1,
        functionIds: "",
        functionParentId: null,
        hireCount: null,
        id: 0,
        jd: "",
        managerId: null,
        monthCount: null,
        monthlySalary: null,
        newprojectManagerId: null,
        permissionUserIds: [],
        position: "",
        projectCityList: [
          {
            cityId: 0,
            cityIds: "",
            cityParentId: 0,
            id: 0,
            projectId: 0,
            version: 0,
          },
        ],
        projectFunctionList: [
          {
            functionId: 0,
            functionIds: "",
            functionParentId: 0,
            id: 0,
            projectId: 0,
            version: 0,
          },
        ],
        secure: false,
        startDate: new Date(),
        status: "进行中",
        version: 0,
      },
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId1: "",
      projectFailType: data.projectFailType,
      source: "",
      detailShowEntry: "",
      pointData: {
        clickTime: "",
        itemCount: 0,
        leaveTime: "",
        projectId: null,
        tabName: "",
      },
      reacodeKey: 0,
      isNo:false
    };
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    customId(newValue, oldValue) {
      this.loadcusInfoById(newValue);
    },
    "formdata.hireCount"(newValue, oldValue) {
      if (newValue && newValue < 1) {
        this.$message.error("需求数量不能小于等于0");
        this.$nextTick(() => {
          this.formdata.hireCount = oldValue;
        });
      }
    },
    "formdata.monthlySalary"(newValue, oldValue) {
      if (newValue && newValue < 1) {
        this.$message.error("月薪不能小于等于0");
        this.$nextTick(() => {
          this.formdata.monthlySalary = oldValue;
        });
      }
      if (newValue && newValue > 999999999) {
        this.$message.error("月薪不能大于999999999");
        this.$nextTick(() => {
          this.formdata.monthlySalary = oldValue;
        });
      }
    },
    "formdata.monthCount"(newValue, oldValue) {
      if (newValue && newValue < 1) {
        this.$message.error("月份不能小于等于0");
        this.$nextTick(() => {
          this.formdata.monthCount = oldValue;
        });
      }
      if (newValue && newValue > 99) {
        this.$message.error("月份不能大于99");
        this.$nextTick(() => {
          this.formdata.monthCount = oldValue;
        });
      }
    },
    activeName(newValue, oldValue) {
      if (oldValue == "7" && oldValue !== newValue) {
        this.handleStatisticsProjectResumedTabClick();
      }
      if (oldValue == "2" && oldValue !== newValue) {
        this.handleStatisticsProjectResumedTabClick();
      }
    },
  },
  methods: {
    LoadContractList(id) {
      //获取合同list
      this.contractList = [];
      getContractBaseInfos({ id: id }).then((res) => {
        if (res.success) {
          this.contractList = res.result;
        }
      });
    },
    addToFavorite() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择公司");
      }
    },
    saveFavorite() {
      this.saveloading = true;
      this.favoriteList.itemIds = [];
      this.favoriteList.itemIds.push(this.projectId);
      addToFavorite(this.favoriteList).then((res) => {
        if (res.success) {
          this.fileManagement = false;
          this.$message.success("操作成功");
          this.saveloading = false;
        } else {
          this.saveloading = false;
        }
      });
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    addMark(data) {
      console.log(data)
      let _this = this;
      //新增备注
      this.dialogMarkVisible = true;
      if (data) {
        this.setStore("RecordItem", data);
        setTimeout(function () {
          _this.$refs.saveMark.loadEditInfo();
        }, 100);
        this.isNo=true
      }else{
        this.isNo=false
      }
    },
    saveMark() {
      //保存
      this.$refs.saveMark.saveMarkRecode();
    },
    loadRecodeList() {
      //加载备注记录List
      this.activeName = "3";
      this.reacodeKey++;
      this.dialogMarkVisible = false;
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId1 = comId;
    },
    toResume(id) {
      //跳人才
      this.$router.push({
        path: "/resume?status=所有人才",
        query: {
          resumeId: id,
        },
      });
    },
    editSchedule(id) {
      this.scheduleTitle = "编辑日程";
      this.scheduleDialog = true;
      editSchedule(id).then((res) => {
        this.propScheduleData = res.result;
      });
    },
    scheduleClick() {
      this.scheduleDialog = true;
      this.scheduleTitle = "新增日程";
    },
    saveSchedule() {
      this.saveloading = true;
      this.$refs.schdeule
        .submitForm("ruleForm")
        .then(() => {
          this.scheduleDialog = false;
          this.scheduleList();
          this.saveloading = false;
        })
        .catch((error) => {
          this.saveloading = false;
        });
    },
    uploadAttach() {
      //上传附件
      this.VisibleAttach = true;
    },
    attachToloadInfo(id) {
      this.loadInfoById(id);
      this.VisibleAttach = false;
      this.activeName = "4";
    },
    loadContactsLitst(id) {
      //获取客户联系人
      if (id) {
        getContactsBaseInfos({ companyId: id }).then((res) => {
          if (res.success) {
            this.contactsList = res.result;
          }
        });
      }
    },
    loadInfoById() {
      //获取详情
      this.screenloading = true;
      this.drawerVisible = false;
      getProjectDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.Info = res.result;
          this.screenloading = false;
          getproRelatedCount(this.$route.query.id).then((ress) => {
            if (ress.success) {
              this.Count = ress.result;
            }
          });
        } else {
          this.screenloading = false;
        }
      });
    },
    handleCloseResult() {
      this.dialogMarkVisible = false;
    },
    handleClose() {
      this.customerDrawerVisible = false;
    },
    EditPro() {
      canOperate({ id: this.projectId }).then((reqs) => {
        if (reqs.success) {
          if (reqs.result || this.getStore("permiss").includes("progream:edit")) {
            this.drawerVisible = true;
          } else {
            this.cancel();
            this.$confirm("您不是项目参与者，无法编辑", "提示", {
              customClass: "altCls",
            })
              .then((_) => {
                done();
              })
              .catch((_) => {});
          }
        }
      });
    },
    loadUserBase() {
      //获取用户列表
      getEnableUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.allChecked = [];
          for (let i in this.userList) {
            this.allChecked.push(this.userList[i].id);
          }
        }
      });
    },
    LoadColumnList() {
      //加载自定义列表
      this.posList = [];
      this.cityList = [];
      getCommonColumnList({
        type: "职能",
      }).then((res) => {
        if (res.success) {
          this.posList = res.result;
          this.posList1 = res.result;
          this.posList1 = this.posList1.map((i) => ({
            ...i,
            disabled: true,
          }));
        }
      });
      cityTree().then((res) => {
        if (res.success) {
          this.cityList = res.result;
        }
      });
    },
    getInfoById(id) {
      //获取编辑详情
      this.projectId = id;
      canOperate({ id: id }).then((reqs) => {
        if (reqs.success) {
          if (reqs.result || this.getStore("permiss").includes("progream:edit")) {
            getProjectById(id).then((res) => {
              this.formLoading = false;
              if (res.success) {
                this.formdata = res.result;
                this.LoadContractList(res.result.companyId);
                this.endOptions = {
                  disabledDate: (date) => {
                    if (this.formdata.startDate) {
                      let beginDate =
                        new Date(this.formdata.startDate).getTime() - 86400000;
                      return date && date < beginDate;
                    }
                  },
                };
                this.formdata.cityIds = this.formdata.projectCityList.map((i) =>
                  JSON.parse(i.cityIds)
                );
                this.formdata.funIds = this.formdata.projectFunctionList.map(
                  (i) => JSON.parse(i.functionIds)
                );
              }
            });
          } else {
            this.cancel();
            this.$confirm("您不是项目参与者，无法编辑", "提示", {
              customClass: "altCls",
            })
              .then((_) => {
                done();
              })
              .catch((_) => {});
          }
        }
      });
    },
    cancel() {
      document.body.click();
    },
    scheduleList() {
      this.scheduleListType.id = this.projectId;
      scheduleList(this.scheduleListType).then((res) => {
        this.scheduleDta = res.result;
      });
    },
    checkVal(formName, type) {
      if (formName == "status" && this.formdata.status == "失败") {
        if (this.formdata.failRemark == "" || !this.formdata.failRemark) {
          this.$message.error("失败原因不能为空");
          return false;
        }
      }
      //修改内容
      if (formName == "monthlySalary") {
        if (this.formdata.monthlySalary && this.formdata.monthCount) {
          addOrEditProject(this.formdata).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              document.body.click();
              this.loadInfoById();
            }
          });
        } else {
          this.$message.error("请输入正确数值");
        }
      } else if (this.formdata[formName]) {
        if (type == "array" && this.formdata[formName].length == 0) {
          this.$message.error("内容不能为空");
          return false;
        }
        addOrEditProject(this.formdata).then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            document.body.click();
            this.loadInfoById();
          }
        });
      } else {
        this.$message.error("内容不能为空");
      }
    },
    handleClick(tab) {
      if (tab.name == "7" || tab.name == "2") {
        this.pointData.clickTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
        this.pointData.projectId = this.projectId;
        this.pointData.tabName = tab.name == "2" ? "候选人" : "相似职位";
        this.pointData.itemCount =
          tab.name == "2"
            ? this.Count.resumeCount
            : this.Count.similarProjectCount;
      }
      this.activeName = tab.name;
      if (tab.name == "5") {
        this.scheduleList();
      }
      if (tab.name == "7") {
        statisticsProjectSimilar({
          originalProjectId: this.projectId,
        }).then();
      }
    },
    Toggle() {
      this.activeName = "1";
    },
    handleStatisticsProjectResumedTabClick() {
      this.pointData.leaveTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      statisticsProjectResumedTabClick(this.pointData).then();
    },
  },
  created() {
    this.projectId = this.$route.query.id;
    if (this.$route.query.resNo) {
      this.resumeNo = eval(this.$route.query.resNo);
    }
    this.detailShowEntry = this.$route.query.detailShowEntry;
    if (this.$route.query.type) {
      if (this.$route.query.type == "SIMILAR_POSITION") {
        this.source = "SIMILAR_POSITION";
      } else {
        //项目阶段
        this.proStatus = this.$route.query.type;
      }
    }
    this.loadInfoById();
    this.LoadColumnList();
    this.loadUserBase(); //获取用户
    this.pointData.clickTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
    this.pointData.tabName = "候选人";
    this.pointData.projectId = this.projectId;
  },
  beforeDestroy() {
    if (this.activeName == "7" || this.activeName == "2") {
      this.handleStatisticsProjectResumedTabClick();
    }
  },
};
</script>
<style lang="scss" scoped>
.drawerCon_head {
  p {
    margin: 0;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    .comStatus {
      font-weight: normal;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      border: 1px solid #56b0f0;
      color: #56b0f0;
      display: inline-block;
      margin-right: 5px;
      font-size: 12px;
      vertical-align: initial;
    }
    .icon-kh_wh {
      color: #898989;
      font-weight: normal;
      vertical-align: bottom;
      &:hover {
        color: #f05656;
      }
    }
  }
  .company {
    color: #526ecc;
    font-style: 14px;
    margin-top: 5px;
    display: inline-block;
  }
  .subInfo {
    font-size: 13px;
    font-weight: normal;
    color: #999;
    float: right;
    i {
      font-style: normal;
      margin-right: 5px;
      color: #606266;
      padding-right: 10px;
      border-right: 1px solid #ddd;
      &:last-child {
        border-right-color: #fff;
        padding-right: 0;
      }
    }
  }
}

.drawerCon_con {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  :deep(.el-tabs__nav-wrap) {
    padding: 0 15px;
  }
  :deep(.el-tabs__nav-wrap::after) {
    height: 1px;
  }
  .info_con {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px;
    .drawerCon_con_tabs {
      flex: 1;
      margin-right: 10px;
      border: 1px solid #e5e5e5;
      :deep(.el-form) {
        padding: 0 40px 0 20px;
      }
      :deep(.el-form-item) {
        margin-bottom: 6px;
      }
      :deep(.el-form-item__label) {
        line-height: 20px !important;
        font-size: 13px;
      }
      :deep(.el-form-item__content) {
        line-height: 20px !important;
        font-size: 13px;
        color: #333;
      }
      .drawerCon_from_tit {
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        color: #333;
        text-align: left;
        font-weight: bold;
        padding: 0 15px;
        margin: 0 0 15px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e5e5e5;
      }
      .el-form-item__content:hover {
        .el-icon-edit {
          display: inline-block;
        }
      }
    }
    .info_con_r {
      width: 240px;
      border: 1px solid #e5e5e5;
      .btnList {
        font-size: 12px;
        color: #606266;
        margin-top: 20px;
        .block {
          width: 50%;
          float: left;
          margin: 0 0 25px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #e60012;
            i {
              color: #e60012;
            }
          }
          i {
            font-size: 24px;
            color: #898989;
          }
          p {
            margin: 5px 0 0;
          }
        }
      }
    }
  }
  .fj_con,
  .mark_con {
    padding: 0 10px 10px;
  }
  .mark_con {
    .time-line {
      position: relative;
      padding: 0 15px;
    }
  }
}
</style>
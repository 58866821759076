<template>
  <div class="similarPositions">
    <div>
      <el-button size="mini" @click="addSimilarPositions"
        >添加相似职位</el-button
      >
      <el-pagination
        :hide-on-single-page="filter.total > 10 ? false : true"
        v-if="filter.total > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.current"
        :page-size="filter.size"
        :pager-count="5"
        layout="total,prev, pager, next,sizes"
        :total="filter.total"
        :page-sizes="[10, 20, 50, 100]"
        style="margin-bottom: 10px"
      ></el-pagination>
    </div>

    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#f5f7fa',
        }"
        :data="ListData"
        class="tabBorder custor"
        v-loading="listLoading"
      >
        <el-table-column width="80" class-name="canEdit">
          <template slot="header" slot-scope="scope"> ID </template>
          <template slot-scope="scope">
            <span
              class="tabHref"
              @click="detailsPop(scope.$index, scope.row)"
              >{{ scope.row.id }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop min-width="250" align="left" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 项目名称 </template>
          <template slot-scope="scope">
            <div>
              <span
                v-if="scope.row.canView"
                class="tabHref"
                :title="
                  scope.row.projectName.length > 12 ? scope.row.projectName : ''
                "
                @click="detailsPop(scope.$index, scope.row, 'SIMILAR_POSITION')"
                >{{ scope.row.projectName | caluTitle(12) }}
              </span>
              <el-tooltip content="无法查看保密项目">
                <span style="cursor: pointer" v-if="!scope.row.canView"
                  >********</span
                >
              </el-tooltip>

              <span class="secureIcon" v-if="scope.row.secure">保密</span
              ><i
                v-if="scope.row.canView"
                @click="showProEdit(scope.row.id, 1)"
                class="el-icon-edit tabHref"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop align="left" class-name="canEdit" min-width="140">
          <template slot="header" slot-scope="scope"> 项目经理 </template>
          <template slot-scope="scope">
            {{ scope.row.managerName }}
          </template>
        </el-table-column>
        <el-table-column prop width="120" align="left" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 创建时间 </template>
          <template slot-scope="scope">
            {{
              scope.row.createdTime
                ? scope.row.createdTime.substring(0, 10)
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="" width="200" align="left" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 操作时间 </template>
          <template slot-scope="scope">
            {{
              scope.row.lastOperateTime
                ? scope.row.lastOperateTime.substring(0, 10)
                : "-"
            }}
            <span
              v-if="scope.row.operateGap"
              class="tag tag02"
              style="margin-left: 8px"
              >{{ scope.row.operateGap }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          align="left"
          min-width="120"
          class-name="canEdit"
        >
          <template slot="header" slot-scope="scope"> 状态 </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <div class="tableType">
                <span
                  class="tag"
                  :class="{
                    tag02: scope.row.status == '进行中',
                    tag03: scope.row.status == '成功',
                    tag05: scope.row.status == '失败',
                    tag06: scope.row.status == '暂停',
                    tag07: scope.row.status == '取消',
                  }"
                  >{{ scope.row.status }}</span
                >
              </div>
              <el-popover
                placement="bottom"
                title="编辑"
                popper-class="popperEdit"
                @show="getInfoById(scope.row.id)"
                trigger="click"
              >
                <i
                  slot="reference"
                  v-if="scope.row.canView"
                  class="el-icon-edit tabHref"
                ></i>
                <div class="popcon">
                  <div class="popcon-input">
                    <el-select
                      filterable
                      v-model="project.status"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in projectType"
                        :key="'c_' + index"
                        :value="item.value"
                        :label="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div style="margin-top: 10px">
                      <el-select
                        v-if="project.status == '失败'"
                        filterable
                        v-model="project.failRemark"
                        placeholder="请选择失败原因"
                      >
                        <el-option
                          v-for="(item, index) in projectFailType"
                          :key="'c_F_' + index"
                          :value="item.val"
                          :label="item.name"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="subBtn">
                    <el-button
                      size="mini"
                      @click="cancel"
                      icon="el-icon-close"
                    ></el-button
                    ><el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-check"
                      @click="checkVal"
                    ></el-button>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="120" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 加入项目 </template>
          <template slot-scope="scope">
            <span
              :class="scope.row.countJoinProject ? 'tabHref' : ''"
              @click="
                scope.row.countJoinProject
                  ? detailsPop(scope.$index, scope.row, 'join')
                  : ''
              "
              v-if="scope.row.canView"
              >{{ scope.row.countJoinProject }}</span
            >
            <el-tooltip content="无法查看保密项目">
              <span v-if="!scope.row.canView">*</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="120" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 简历推荐 </template>
          <template slot-scope="scope">
            <span
              :class="scope.row.countResumeRecommend ? 'tabHref' : ''"
              @click="
                scope.row.countResumeRecommend
                  ? detailsPop(scope.$index, scope.row, 'recommend')
                  : ''
              "
              v-if="scope.row.canView"
              >{{ scope.row.countResumeRecommend }}</span
            >
            <el-tooltip content="无法查看保密项目">
              <span v-if="!scope.row.canView">*</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="120" class-name="canEdit">
          <template slot="header" slot-scope="scope"> 客户面试 </template>
          <template slot-scope="scope">
            <span
              :class="scope.row.countClientInterview ? 'tabHref' : ''"
              @click="
                scope.row.countClientInterview
                  ? detailsPop(scope.$index, scope.row, 'interview')
                  : ''
              "
              v-if="scope.row.canView"
              >{{ scope.row.countClientInterview }}</span
            >
            <el-tooltip content="无法查看保密项目">
              <span v-if="!scope.row.canView">*</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="120" class-name="canEdit">
          <template slot="header" slot-scope="scope"> Offer </template>
          <template slot-scope="scope">
            <span
              :class="scope.row.countOffer ? 'tabHref' : ''"
              @click="
                scope.row.countOffer
                  ? detailsPop(scope.$index, scope.row, 'offer')
                  : ''
              "
              v-if="scope.row.canView"
              >{{ scope.row.countOffer }}</span
            >
            <el-tooltip content="无法查看保密项目">
              <span v-if="!scope.row.canView">*</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="filter.total > 10 ? false : true"
        v-if="filter.total > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.current"
        :page-size="filter.size"
        :pager-count="5"
        layout="total,prev, pager, next,sizes"
        :total="filter.total"
        :page-sizes="[10, 20, 50, 100]"
        style="margin-bottom: 20px"
      ></el-pagination>
    </div>
    <el-dialog
      :visible.sync="proNameVisible"
      :modal-append-to-body="false"
      append-to-body
      title="编辑"
      :close-on-click-modal="false"
      width="600px"
      :before-close="closeDialog"
    >
      <el-form
        @submit.native.prevent
        :model="project"
        ref="project"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <p class="DialogTips">
          <i class="el-icon-warning"></i
          >项目名称是由‘职位+公司简称’自动生成的，您可以在此处修改职位名称
        </p>
        <el-form-item
          style="margin-bottom: 10px"
          prop="position"
          label="职位名称："
        >
          <el-input
            placeholder="请输入新的职位名称"
            v-model.trim="project.position"
            maxlength="50"
            @focus="showNewProject = true"
          ></el-input>
        </el-form-item>
        <el-form-item label="" style="margin: 0" v-if="showNewProject">
          <p style="font-size: 13px; color: #606266; line-height: 1">
            预览新项目名称：{{
              project.position + "-" + project.commonCompanyName
            }}
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            proNameVisible = false;
            showNewProject = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="checkVal('project')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加相似职位 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">添加相似职位</div>
      <div class="drawerCon">
        <div class="fl searchL" style="width: 35%; margin-bottom: 10px">
          <el-input
            class="search"
            v-model="addFilter.filter"
            @keyup.enter.native="submitForm('addFilter')"
            placeholder="请输入项目名称 / 项目经理 /参与者/ 公司名称，支持模糊搜索"
          >
            <el-button
              type="primary"
              slot="append"
              @click="submitForm('addFilter')"
              >搜 索</el-button
            >
          </el-input>
        </div>
        <div>
          <el-pagination
            :hide-on-single-page="addFilter.total > 10 ? false : true"
            v-if="addFilter.total > 0"
            @size-change="handleAddSizeChange"
            @current-change="handleAddCurrentChange"
            :current-page="addFilter.current"
            :page-size="addFilter.size"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="addFilter.total"
            :page-sizes="[10, 20, 50, 100]"
            style="margin-bottom: 0px"
          ></el-pagination>
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="addListData"
            class="tabBorder custor"
            v-loading="addlistLoading"
          >
            <el-table-column width="80" label="ID" prop="id"> </el-table-column>
            <el-table-column
              label="项目名称"
              prop="projectName"
              min-width="250"
              align="left"
            >
            </el-table-column>
            <el-table-column
              label="项目经理"
              prop="managerName"
              align="left"
              min-width="140"
            >
            </el-table-column>
            <el-table-column
              label="创建时间"
              prop="createdTime"
              width="120"
              align="left"
            >
              <template slot-scope="scope">
                {{
                  scope.row.createdTime
                    ? scope.row.createdTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作时间"
              prop="lastOperateTime"
              width="200"
              align="left"
            >
              <template slot-scope="scope">
                {{
                  scope.row.lastOperateTime
                    ? scope.row.lastOperateTime.substring(0, 10)
                    : "-"
                }}
                <span
                  v-if="scope.row.operateGap"
                  class="tag tag02"
                  style="margin-left: 8px"
                  >{{ scope.row.operateGap }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              prop="status"
              align="left"
              min-width="120"
            >
              <template slot-scope="scope">
                <div>
                  <div class="tableType">
                    <span
                      class="tag"
                      :class="{
                        tag02: scope.row.status == '进行中',
                        tag03: scope.row.status == '成功',
                        tag05: scope.row.status == '失败',
                        tag06: scope.row.status == '暂停',
                        tag07: scope.row.status == '取消',
                      }"
                      >{{ scope.row.status }}</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="120" label="操作">
              <template slot-scope="scope">
                <span class="tabHref" @click="add(scope.row.id)">添加</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="addFilter.total > 10 ? false : true"
            v-if="addFilter.total > 0"
            @size-change="handleAddSizeChange"
            @current-change="handleAddCurrentChange"
            :current-page="addFilter.current"
            :page-size="addFilter.size"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="addFilter.total"
            :page-sizes="[10, 20, 50, 100]"
            style="margin-bottom: 20px"
          ></el-pagination>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getProjectById, //获取编辑详情
  addOrEditProject, //编辑保存
  canOperate,
  permissionSecure, //是否有项目的查看权限
  getSimilarProject, //相似职位列表
  postSimilarProject, //添加相似职位
  getSimilarProjectAddList, //添加相似职位选项列表
} from "../../api/api";
import {
  statisticsProjectSimilar, //记录相似职位点击与添加成功
  statisticsProjectSimilarClick, //记录项目-项目详情-相似职位tab-列表-项目名称和ID点击量
} from "../../api/buryingPoint";
import * as data from "../../common/json/commData.json";
export default {
  name: "similarPositions",
  props: ["projectId"],
  data() {
    return {
      ListData: [],
      listLoading: false,
      proNameVisible: false,
      project: {
        status: "",
        managerId: [],
        positionCompanyName: "",
      },
      filter: {
        current: 1,
        size: 50,
        projectId: null,
        total: 0,
      },
      projectType: data.projectType,
      projectFailType: data.projectFailType,
      showNewProject: false,
      drawerVisible: false,
      addlistLoading: false,
      addListData: [],
      addFilter: {
        current: 1,
        size: 50,
        projectId: null,
        total: 0,
        filter: null,
      },
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    addSimilarPositions() {
      this.drawerVisible = true;
      this.getAddList();
    },
    detailsPop(index, row, type) {
      if (!type || type == "SIMILAR_POSITION") {
        statisticsProjectSimilarClick({
          idOrName: type == "SIMILAR_POSITION" ? false : true,
          projectId: row.id,
          projectName: row.projectName,
          rank: (this.filter.current - 1) * this.filter.size + (index + 1),
        }).then();
      }
      permissionSecure({ id: row.id }).then((res) => {
        if (res.success) {
          if (res.result) {
            let newUrl = this.$router.resolve({
              path: "/project/details",
              query: { id: row.id, type: type },
            });
            window.open(newUrl.href, "_blank");
          } else {
            this.$message.error("暂无查看权限");
          }
        }
      });
    },
    showProEdit(id, type) {
      this.getInfoById(id, type);
    },
    closeDialog() {
      this.proNameVisible = false;
      this.showNewProject = false;
    },
    //获取编辑详情
    getInfoById(id, type) {
      canOperate({ id: id }).then((reqs) => {
        if (reqs.success) {
          if (reqs.result || this.getStore("permiss").includes("progream:edit")) {
            getProjectById(id).then((res) => {
              if (res.success) {
                if (type) {
                  this.proNameVisible = true;
                }
                this.project = res.result;
                // this.project.allCityIds = eval(res.result.project.cityIds);
                this.$set(this.project, "companyIndustryIds", []);
                if (
                  res.result.companyIndustryList &&
                  res.result.companyIndustryList.length > 0
                ) {
                  res.result.companyIndustryList.forEach((item, index) => {
                    if (item.industryIds) {
                      this.project.companyIndustryIds[index] = eval(
                        item.industryIds
                      );
                    }
                  });
                }
              }
            });
          } else {
            this.cancel();
            this.$alert("您不是项目参与者，无法编辑", "提示", {
              showConfirmButton: false,
              customClass: "altCls",
            });
          }
        }
      });
    },
    cancel() {
      document.body.click();
    },
    //修改内容
    checkVal() {
      if (this.project.positionCompanyName == "") {
        this.$message.error("修改内容不能为空");
        return false;
      }
      if (this.project.status == "失败") {
        if (this.project.failRemark == "" || !this.project.failRemark) {
          this.$message.error("失败原因不能为空");
          return false;
        }
      }
      addOrEditProject(this.project).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          document.body.click();
          this.loadList();
          this.proNameVisible = false;
        }
      });
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      this.filter.projectId = this.projectId;
      getSimilarProject(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.size = val;
      this.filter.current = 1;
      this.loadList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.current = val;
      this.loadList();
    },
    //关闭相似职位抽屉
    handleClose() {
      this.drawerVisible = false;
      this.loadList();
    },
    submitForm() {
      this.addFilter.size = 50;
      this.addFilter.current = 1;
      this.getAddList();
    },
    getAddList() {
      $(".el-drawer__body").scrollTop(0);
      this.addlistLoading = true;
      this.addFilter.projectId = this.projectId;
      getSimilarProjectAddList(this.addFilter).then((res) => {
        if (res.success) {
          this.addlistLoading = false;
          this.addListData = res.result.records;
          this.addFilter.total = res.result.total;
        }
        this.addlistLoading = false;
      });
    },
    add(id) {
      postSimilarProject({
        projectId: this.projectId,
        similarId: id,
      }).then((res) => {
        if (res.success) {
          statisticsProjectSimilar({
            originalProjectId: this.projectId,
            similarProjectId: id,
          }).then();
          this.$message.success("添加成功");
          this.getAddList();
        }
      });
    },
    handleAddSizeChange(val) {
      this.addFilter.size = val;
      this.addFilter.current = 1;
      this.getAddList();
    },
    handleAddCurrentChange(val) {
      this.addFilter.current = val;
      this.getAddList();
    },
  },
};
</script>
<style lang='scss' scoped>
.searchL {
  width: 100%;
  margin-bottom: 15px;
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
</style>
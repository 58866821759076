<template>
  <section class="dataOverview">
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <div style="margin-bottom: 10px">
        <el-radio-group
          v-model="tabType"
          size="mini"
          @change="changeTabType"
          style="margin-top: -2px"
        >
          <el-radio-button label="全部"></el-radio-button
          ><el-radio-button label="本季"></el-radio-button>
          <el-radio-button label="本月"></el-radio-button>
          <el-radio-button label="本周"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          v-model="timeVal"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="loadList"
          style="margin-left: 10px"
        >
        </el-date-picker>
      </div>
      <el-col :span="12" class="content">
        <div class="titleBox">
          <p class="tit">总推进数据</p>
        </div>
        <div
          class="bar botchart"
          :style="{ width: '100%', margin: '0 auto' }"
          ref="allPromote"
          id="allPromote"
        ></div>
      </el-col>
      <el-col :span="12" class="content">
        <div class="titleBox">
          <p class="tit">个人推进数据</p>
          <el-select
            clearable
            class="more"
            v-model="promoteUserId"
            style="width: 180px"
            @change="getProjectProjectBoostOverview('personal')"
          >
            <el-option
              v-for="(item, index) in userList"
              :key="index"
              :label="item.userName"
              :value="item.userId"
              >{{ item.userName }}</el-option
            >
          </el-select>
        </div>
        <div
          v-if="promoteUserId"
          class="bar botchart"
          :style="{ width: '100%', margin: '0 auto' }"
          ref="personalPromote"
          id="personalPromote"
        ></div>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div>
      <el-row
        :span="24"
        class="body"
        style="margin-left: 0; margin-right: 0"
        :gutter="20"
      >
        <el-col :span="12" class="content">
          <div class="titleBox">
            <p class="tit">总淘汰原因分析</p>
          </div>
          <div
            class="pie botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="totalReasons"
            id="totalReasons"
          ></div>
        </el-col>
        <el-col :span="12" class="content">
          <div class="titleBox">
            <p class="tit">个人淘汰原因分析</p>
            <el-select
              clearable
              class="more"
              v-model="eliminateUserId"
              style="width: 180px"
              @change="getProjectProjectDeliveryOverview()"
            >
              <el-option
                v-for="(item, index) in userList"
                :key="index"
                :label="item.userName"
                :value="item.userId"
                >{{ item.userName }}</el-option
              >
            </el-select>
          </div>
          <div
            v-if="eliminateUserId"
            class="pie botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="personalReasons"
            id="personalReasons"
          ></div>
        </el-col>
      </el-row>
      <el-row
        :span="24"
        class="body"
        style="margin-left: 0; margin-right: 0"
        :gutter="20"
      >
        <el-col :span="12" class="content">
          <div class="titleBox">
            <p class="tit">Offer周期</p>
            <span style="font-size: 12px; color: #999; font-weight: 400"
              >（Offer周期指从首个加入项目到首个Offer的总时长，按整体的项目维度统计）</span
            >
          </div>
          <div
            class="bar botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="periodicity"
            id="periodicity"
          ></div>
        </el-col>
        <el-col :span="12" class="content">
          <div class="titleBox">
            <p class="tit">
              推进时长
              <span style="font-size: 12px; color: #999; font-weight: 400"
                >（所有候选人各阶段的平均推进时长）</span
              >
            </p>
          </div>
          <div
            class="bar botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="duration"
            id="duration"
          ></div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import {
  getPermissionUsers,
  projectProjectBoostOverview, //项目推进数据概览
  projectProjectDeliveryOverview, //项目淘汰、推进时长、Offer周期的数据概览
} from "../../api/api";
import * as echarts from "echarts";
export default {
  props: ["projectId"],
  data() {
    return {
      tabType: "全部",
      timeVal: [],
      userId: parseInt(this.getStore("uid")),
      eliminateUserId: null,
      promoteUserId: null,
      boostOverviewData: {},
      deliveryOverviewData: {},
      userList: [],
    };
  },
  mounted() {
    // this.drawGaugeChart()
    // console.log(this.permissionUserIds);
    this.getPermissionUsers();
  },
  methods: {
    //切换类型
    changeTabType(val) {
      this.getProjectProjectBoostOverview("all");
      this.getProjectProjectBoostOverview("personal");
    },
    //查询
    loadList(val) {
      this.timeVal = val ? val : [];
      this.getProjectProjectBoostOverview("all");
      this.getProjectProjectBoostOverview("personal");
    },
    getPermissionUsers() {
      getPermissionUsers({
        projectId: Number(this.projectId),
      }).then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.$nextTick(() => {
            for (var i = 0; i < this.userList.length; i++) {
              if (this.userList[i].userId == this.userId) {
                this.eliminateUserId = this.userId;
                this.promoteUserId = this.userId;
                break;
              } else {
                this.eliminateUserId = null;
                this.promoteUserId = null;
              }
            }
            this.getProjectProjectBoostOverview("all");
            this.getProjectProjectBoostOverview("personal");
            this.getProjectProjectDeliveryOverview();
          });
        }
      });
    },
    //项目推进数据概览
    getProjectProjectBoostOverview(type) {
      projectProjectBoostOverview({
        userId: type == "all" ? null : this.promoteUserId,
        projectId: Number(this.projectId),
        begin: this.timeVal ? this.timeVal[0] : "",
        end: this.timeVal ? this.timeVal[1] : "",
        period: this.tabType,
      }).then((res) => {
        if (res.success) {
          this.boostOverviewData = res.result;
          let list = [];
          list = [
            {
              value: this.boostOverviewData.suc,
              rata: this.boostOverviewData.sucRate,
            },
            {
              value: this.boostOverviewData.entry,
              rata: this.boostOverviewData.entryRate,
            },
            {
              value: this.boostOverviewData.offer,
              rata: this.boostOverviewData.offerRate,
            },
            {
              value: this.boostOverviewData.interviewFinal,
              rata: this.boostOverviewData.interviewFinalRate,
            },
            {
              value: this.boostOverviewData.interviewFirst,
              rata: this.boostOverviewData.interviewFirstRate,
            },
            {
              value: this.boostOverviewData.resumeRecommend,
              rata: this.boostOverviewData.resumeRecommendRate,
            },
            {
              value: this.boostOverviewData.addProject,
              rata: 0,
            },
            // this.boostOverviewData.suc,
            // this.boostOverviewData.entry,
            // this.boostOverviewData.offer,
            // this.boostOverviewData.interviewFinal,
            // this.boostOverviewData.interviewFirst,
            // this.boostOverviewData.resumeRecommend,
            // this.boostOverviewData.addProject,
          ];
          if (type == "all") {
            this.drawHorizontalBarChart("allPromote", "总推进数据", list);
          } else {
            this.drawHorizontalBarChart(
              "personalPromote",
              "个人推进数据",
              list
            );
          }
        }
      });
    },
    //项目淘汰、推进时长、Offer周期的数据概览
    getProjectProjectDeliveryOverview() {
      projectProjectDeliveryOverview({
        userId: this.eliminateUserId,
        projectId: this.projectId,
      }).then((res) => {
        if (res.success) {
          this.deliveryOverviewData = res.result;
          this.drawBarChart();
          this.drawGaugeChart();
          let allEliminateAnalyzeData = [];
          let personalEliminateAnalyzeData = [];
          allEliminateAnalyzeData = [
            {
              name: "客户淘汰",
              value:
                this.deliveryOverviewData.allEliminateAnalyze.customerEliminate,
            },
            {
              name: "候选人拒绝",
              value:
                this.deliveryOverviewData.allEliminateAnalyze.candidateRefused,
            },
            {
              name: "未过试用期",
              value:
                this.deliveryOverviewData.allEliminateAnalyze.trialPeriodFailed,
            },
            {
              name: "试用期离职",
              value:
                this.deliveryOverviewData.allEliminateAnalyze
                  .trialPeriodDeparture,
            },
            {
              name: "其他",
              value: this.deliveryOverviewData.allEliminateAnalyze.other,
            },
          ];
          personalEliminateAnalyzeData = [
            {
              name: "客户淘汰",
              value: this.deliveryOverviewData.personalEliminateAnalyze
                ? this.deliveryOverviewData.personalEliminateAnalyze
                    .customerEliminate
                : 0,
            },
            {
              name: "候选人拒绝",
              value: this.deliveryOverviewData.personalEliminateAnalyze
                ? this.deliveryOverviewData.personalEliminateAnalyze
                    .candidateRefused
                : 0,
            },
            {
              name: "未过试用期",
              value: this.deliveryOverviewData.personalEliminateAnalyze
                ? this.deliveryOverviewData.personalEliminateAnalyze
                    .trialPeriodFailed
                : 0,
            },
            {
              name: "试用期离职",
              value: this.deliveryOverviewData.personalEliminateAnalyze
                ? this.deliveryOverviewData.personalEliminateAnalyze
                    .trialPeriodDeparture
                : 0,
            },
            {
              name: "其他",
              value: this.deliveryOverviewData.personalEliminateAnalyze
                ? this.deliveryOverviewData.personalEliminateAnalyze.other
                : 0,
            },
          ];
          this.drawPieChart(
            "totalReasons",
            "总淘汰原因分析",
            allEliminateAnalyzeData
          );
          this.drawPieChart(
            "personalReasons",
            "个人淘汰原因分析",
            personalEliminateAnalyzeData
          );
        }
      });
    },
    //推进数据
    drawHorizontalBarChart(id, name, data) {
      var maxNum = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].value > maxNum) {
          maxNum = data[i].value;
        }
      }
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          //   formatter: "{a} <br/>{b} : {c}%",
        },
        grid: {
          left: "3%",
          top: "35px",
          right: "10%",
          bottom: "0px",
          containLabel: true,
        },
        // title: {
        //   text: name,
        //   textStyle: {
        //     fontSize: 16,
        //     color: "#333",
        //   },
        //   left: "0",
        //   top: "0",
        // },
        yAxis: {
          type: "category",
          data: [
            "成功",
            "入职",
            "Offer",
            "客户面试（终面）",
            "客户面试（1面）",
            "简历推荐",
            "加入项目",
          ],
          axisLine: {
            // show: false,
            lineStyle: {
              color: "#999",
            },
          },
        },
        xAxis: {
          // type: "value",
          show: false, //是否显示坐标轴中的x轴
        },
        series: [
          {
            // data: data,
            data: data.map(function (item) {
              return {
                value: item.value,
                maxNum: maxNum,
                rata: item.rata,
              };
            }),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            label: {
              show: true,
              position: "right",
              align: "left",
              offset: [0, 0],
              formatter: function (params) {
                // return params.data.realValue;
                var percent =
                  params.data.rata !== 0
                    ? params.data.value + "(" + params.data.rata + "%)"
                    : params.data.value;
                return percent;
              },
              fontSize: 12,
            },
          },
        ],
      };
      setTimeout(function () {
        var width = myChart.getWidth();
        var opt = myChart.getOption();
        var grid = opt.grid[0];
        var right = grid.right;
        var left = grid.left;
        right = (width * parseFloat(right)) / 100;
        left = (width * parseFloat(left)) / 100;
        var x = width - left - right - 90;
        // console.log(x)
        // option.series[0].label.offset = [x + 50, 0]
        myChart.setOption({
          series: [
            {
              label: {
                show: true,
                position: "left",
                offset: [x, 0],
              },
            },
          ],
        });
      });
      // console.log(option)
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //淘汰原因分析图表
    drawPieChart(id, name, data) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        color: ["#e86666", "#fe8943", "#f2c561", "#92d671", "#b7bee2"],
        grid: {
          left: "3%",
          top: "0",
          right: "4%",
          bottom: "15",
          containLabel: true,
        },
        // title: {
        //   text: name,
        //   textStyle: {
        //     fontSize: 16,
        //     color: "#333",
        //   },
        //   left: "0",
        //   top: "0",
        // },
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          data: ["客户淘汰", "候选人拒绝", "未过试用期", "试用期离职", "其他"],
          height: "60%",
          right: "30%",
          top: "28%",
          icon: "circle",
          textStyle: {
            width: 80,
          },
        },
        series: [
          {
            name: "淘汰原因",
            type: "pie",
            radius: ["35%", "60%"],
            center: ["30%", "52%"],
            data: data,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {
                show: false,
                position: "center",
                formatter: "{value|{c}}\n{label|{b}}",
                rich: {
                  value: {
                    padding: 5,
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 18,
                  },
                  label: {
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 12,
                  },
                },
              },
            },
            itemStyle: {
              // borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //推进时长
    drawBarChart() {
      var chartDom = document.getElementById("duration");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // title: {
        //   text: "推进时长",
        //   subtext: "（所有候选人各阶段的平均推进时长）",
        //   textStyle: {
        //     fontSize: 16,
        //     color: "#333",
        //   },
        //   left: "0",
        //   top: "0",
        // },
        legend: {
          top: "10px",
          itemHeight: 10,
          pageIconSize: 10,
          itemWidth: 20,
          textStyle: {
            color: "#606266",
            lineHeight: 18,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item, index) => {
              str =
                str +
                "<br/>" +
                item.marker +
                item.seriesName +
                " : " +
                item.value +
                "天";
            });
            return str;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} 天",
          },
        },
        xAxis: {
          type: "category",
          data: ["加入项目-推荐", "推荐-1面", "1面-offer", "offer-入职"],
        },
        series: [
          {
            name: "【" + this.deliveryOverviewData.curUserName + "】数据",
            type: "bar",
            data: [
              this.deliveryOverviewData.curUserResumeRecommend,
              this.deliveryOverviewData.curUserInterviewFirst,
              this.deliveryOverviewData.curUserOffer,
              this.deliveryOverviewData.curUserEntry,
            ],
          },
          {
            name: "总数据",
            type: "bar",
            data: [
              this.deliveryOverviewData.sumResumeRecommend,
              this.deliveryOverviewData.sumInterviewFirst,
              this.deliveryOverviewData.sumOffer,
              this.deliveryOverviewData.sumEntry,
            ],
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //Offer周期
    drawGaugeChart() {
      var chartDom = document.getElementById("periodicity");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          // formatter: "{a} <br/>{b} : {c}%",
        },
        // title: {
        //   text: "Offer周期指从首个加入项目到首个Offer的总时长，按整体的项目维度统计",
        //   textStyle: {
        //     fontSize: 12,
        //     color: "#999",
        //     fontWeight: 400,
        //   },
        //   left: "center",
        //   bottom: "0",
        // },
        grid: {
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "15%",
          containLabel: true,
        },
        series: [
          {
            name: "Offer周期",
            type: "gauge",
            detail: {
              formatter: "{value}天",
              show: true,
              fontSize: 20,
            },
            data: [
              {
                value: this.deliveryOverviewData.offerCycle,
                // name: "天",
              },
            ],
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  height: 280px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1366px) {
    height: 300px;
  }
}
.botchart {
  height: 260px;
  @media (max-width: 1366px) {
    height: 220px;
  }
}
.content {
  padding: 10px;
  //   border: 1px #e5e5e5 solid;
  &:hover {
    -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
    box-shadow: 0 3px 8px 1px #e0e0e0;
  }
}
.titleBox {
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: "#333";
  height: 32px;
  p {
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.dataOverview {
  .el-radio-button__inner {
    padding: 9px 15px;
  }
  .el-empty {
    .el-empty__image {
      width: 100px;
      height: 100px;
      margin: 20px auto;
    }
    .el-empty__description {
      margin-top: 10px;
      text-align: center;
      color: #999;
    }
  }
  .el-divider--horizontal {
    height: 8px;
    margin: 20px 0;
  }
  .el-divider {
    background-color: #f1f1f1;
  }
}
</style>
